import React, { useState } from "react";
import "./EducationSection.css";

const EducationSection = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  const educations = [
    {
      title: "STX",
      duration: "Espergærde gymnasium og HF, 2016 - 2019",
      description:
        "Linje: Samfundsfag (A), Matematik (B), Mediefag (A). Uddannelsen gav en bred forståelse af samfundsforhold og analytiske færdigheder samt kreativitet gennem mediefag.",
    },
    {
      title: "Markedsføringsøkonom",
      duration: "Cphbusiness, 2021 - 2023",
      description:
        "Fokus på international markedsføring, salg, økonomi, kommunikation og ledelse. Uddannelsen inkluderede praktisk erfaring gennem projektarbejde, caseøvelser og en afsluttende praktikperiode, der forberedte mig på arbejdsmarkedet med en stærk erhvervsrettet og praksisrelateret tilgang​.",
    },
    // Add more education entries as needed
  ];

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : educations.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < educations.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section id="education" className="education-section">
      <h2>Uddannelse.</h2>
      <div className="edu-carousel-container">
        <div
          className="edu-carousel"
          style={{
            transform: `translateX(calc(50% - ${activeIndex * 64}vw - 33.5vw))`,
          }}
        >
          {educations.map((edu, index) => (
            <div
              key={index}
              className={`edu-card ${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              <h3>{edu.title}</h3>
              <p className="edu-duration">{edu.duration}</p>
              <p className="edu-description">{edu.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-controls">
        <button onClick={handlePrevClick} className="carousel-button">
          ❮
        </button>
        <button onClick={handleNextClick} className="carousel-button">
          ❯
        </button>
      </div>
    </section>
  );
};

export default EducationSection;
