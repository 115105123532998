import React, { useState } from "react";
import "./ExperienceSection.css";

const ExperienceSection = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  const experiences = [
    {
      title: "Casino Dealer",
      duration: "Casino Marienlyst, Maj 2019 - September 2021",
      description:
        "Opretholdte høj kvalitet i kundeservice og professionalisme i et tempofyldt miljø.",
    },
    {
      title: "Marketingassistent",
      duration: "Niibuhr Jewelry, Januar 2023 - April 2023",
      description:
        "Fokuserede på at forbedre SEO og skabe engagerende indhold til sociale medier. Dette omfattede søgeordsanalyse, on-page SEO, linkbuilding og mere.",
    },
    {
      title: "Marketingkonsulent",
      duration: "Restaurant Mantzius, Februar 2024 - Marts 2024",
      description:
        "Under min praktikperiode koncentrerede jeg mig om at opdatere deres hjemmeside, hvilket involverede produktion af videoer/fotos, styring af indhold på WordPress og forbedring af SEO.",
    },
    {
      title: "Marketingkonsulent",
      duration: "Rustfri Design ApS, Marts 2024 - April 2024",
      description:
        "Udviklede og optimerede virksomhedens produktkatalog ved at tage højkvalitetsfotos, skrive produktbeskrivelser og designe kataloglayoutet.",
    },
    // Add more experiences as needed
  ];

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : experiences.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < experiences.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section id="experience" className="experience-section">
      <h2>Erhvervserfaring.</h2>
      <div className="exp-carousel-container">
        <div
          className="exp-carousel"
          style={{
            transform: `translateX(calc(50% - ${activeIndex * 64}vw - 35vw))`,
          }}
        >
          {experiences.map((exp, index) => (
            <div
              key={index}
              className={`exp-card ${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              <h3>{exp.title}</h3>
              <p className="exp-duration">{exp.duration}</p>
              <p className="exp-description">{exp.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-controls">
        <button onClick={handlePrevClick} className="carousel-button">
          ❮
        </button>
        <button onClick={handleNextClick} className="carousel-button">
          ❯
        </button>
      </div>
    </section>
  );
};

export default ExperienceSection;
