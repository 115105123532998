import React from "react";
import "./NameSection.css";

const NameSection = () => {
  return (
    <div className="name-section">
      <h1>
        <span className="light">Charlie B.</span>{" "}
        <span className="bold">Zernickov</span>
      </h1>
      <p>
        Innovativ og alsidig professionel med en passion for digital
        markedsføring, teknologi og kreativitet
      </p>
    </div>
  );
};

export default NameSection;
