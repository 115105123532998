import React, { useState } from "react";
import "./SkillsSection.css";

const SkillsSection = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  const skills = [
    {
      title: "Analytisk tænkning og strategisk planlægning",
      duration: "Ekspert",
    },
    {
      title: "Søgemaskineoptimering (SEO)",
      duration: "Fremskreden",
    },
    {
      title: "Content Creation og Grafisk Design",
      duration: "Ekspert",
    },
    {
      title: "Stærke Kommunikations- og Samarbejdsevner",
      duration: "Ekspert",
    },
    {
      title: "Hurtig læring og tilpasning til nye teknologier",
      duration: "Ekspert",
    },
    {
      title: "Teknologisk forståelse og digitale færdigheder",
      duration: "Fremskreden",
    },
    // Add more skills entries as needed
  ];

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : skills.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < skills.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section id="skills" className="skills-section">
      <h2>Kompetencer.</h2>
      <div className="ski-carousel-container">
        <div
          className="ski-carousel"
          style={{
            transform: `translateX(calc(50% - ${activeIndex * 64}vw - 35vw))`,
          }}
        >
          {skills.map((skill, index) => (
            <div
              key={index}
              className={`ski-card ${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              <h3>{skill.title}</h3>
              <p className="ski-duration">{skill.duration}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-controls">
        <button onClick={handlePrevClick} className="carousel-button">
          ❮
        </button>
        <button onClick={handleNextClick} className="carousel-button">
          ❯
        </button>
      </div>
    </section>
  );
};

export default SkillsSection;
