import React from "react";
import "./NavigationBar.css";

const NavigationBar = ({ showNav }) => {
  const handleScrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className={`navigation-bar ${showNav ? "visible" : ""}`}>
      <div className="nav-left">
        <a href="#home" className="nav-name" onClick={handleScrollToTop}>
          <span className="light">Charlie B.</span>{" "}
          <span className="bold">Zernickov</span>
        </a>
      </div>
      <div className="nav-right">
        <a href="mailto:charlie@czer.dk" className="contact-link">
          Kontakt
        </a>
      </div>
    </nav>
  );
};

export default NavigationBar;
