import React, { useState } from "react";
import "./ITSection.css";

const ITSection = () => {
  const [activeIndex, setActiveIndex] = useState(3);

  const ITs = [
    {
      title: "Microsoft Office",
      duration: "Ekspert",
      description: "Word, Excel & Powerpoint",
    },
    {
      title: "CMS-Systemer",
      duration: "Fremskreden",
      description: "Wordpress & Shopify",
    },
    {
      title: "Adobe Creative Suite",
      duration: "Ekspert",
      description: "Illustrator, XD, inDesign & Photoshop",
    },
    {
      title: "SEO",
      duration: "Fremskreden",
      description: "Screaming Frog SEO Spider, AHREFS, SEMrush & Moz",
    },
    {
      title: "SoMe",
      duration: "God",
      description: "Annonceadministrator",
    },
    {
      title: "Google",
      duration: "Ekspert",
      description: "Google Search Console, Analytics & Adwords",
    },
    {
      title: "AI",
      duration: "Ekspert",
      description: "ChatGPT, Google Bard, Copilot, Dall-E, Invideo AI, Runway",
    },
    // Add more experiences as needed
  ];

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : ITs.length - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < ITs.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section id="its" className="it-section">
      <h2>IT.</h2>
      <div className="it-carousel-container">
        <div
          className="it-carousel"
          style={{
            transform: `translateX(calc(50% - ${activeIndex * 64}vw - 35vw))`,
          }}
        >
          {ITs.map((it, index) => (
            <div
              key={index}
              className={`it-card ${index === activeIndex ? "active" : ""}`}
              onClick={() => setActiveIndex(index)}
            >
              <h3>{it.title}</h3>
              <p className="it-duration">{it.duration}</p>
              <p className="it-description">{it.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-controls">
        <button onClick={handlePrevClick} className="carousel-button">
          ❮
        </button>
        <button onClick={handleNextClick} className="carousel-button">
          ❯
        </button>
      </div>
    </section>
  );
};

export default ITSection;
