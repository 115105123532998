import React, { useState, useEffect } from "react";
import "./App.css";
import NameSection from "./components/NameSection";
import ExperienceSection from "./components/ExperienceSection";
import SkillsSection from "./components/SkillsSection";
import NavigationBar from "./components/NavigationBar";
import EducationSection from "./components/EducationSection";
import ITSection from "./components/ITSection";

function App() {
  const [showNav, setShowNav] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > 100) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="top" className="App">
      <NavigationBar showNav={showNav} />
      <NameSection />
      <ExperienceSection />
      <EducationSection />
      <SkillsSection />
      <ITSection />
    </div>
  );
}

export default App;
